import React from "react";
import ScrollToTop from "../../Shared/ScrollToTop";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";
import Support from "../../Shared/Support";
import GIT from "../../Shared/GIT";

import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <Header />

      <div className="about-container">
        {/* <Box sx={{ width: '100%' }} style={{ marginTop: '80px' }}> */}
        <div className="row">
          <div className="col-12 col-lg-6 col-sm-12 col-md-12">
            <div className="about-title"> LA CASA DE PADEL</div>
            <div className="about-subtitle">Fondé en 2024</div>
            <div className="about-content">
              <ul>
                <li>
                Notre club, situé a Sidi Mansour, est un havre pour les passionnés de padel. Fondé en 2024, nous nous engageons à promouvoir ce sport dynamique et à créer une communauté solide autour du padel.
                </li>
                <li>
                Doté de terrains de padel modernes, notre club offre une expérience de jeu exceptionnelle. Les joueurs de tous niveaux trouveront des installations adaptées à leur pratique, que ce soit pour l'entraînement ou pour la compétition.
                </li>
                <li>
                Au-delà du sport, notre club est un lieu de rencontre et d'échange. Nous organisons régulièrement des événements sociaux, des tournois et des activités pour renforcer l'esprit de communauté parmi nos membres.
                </li>
                <li>
                Que vous soyez un joueur débutant ou expérimenté, notre club est ouvert à tous. Nous proposons des cours, des programmes d'entraînement et des conseils personnalisés pour aider chacun à atteindre ses objectifs sportifs.

                </li>
              </ul>
            </div>
          </div>
          <div className="hiden-sm col-12 col-lg-6 col-sm-12 col-md-12 ">
            <div className="img-cont"></div>
          </div>
        </div>
      </div>
      <Support />

      <div className="row">
        <GIT />
      </div>
      <div className="row">{/* <Maps /> */}</div>

      <Footer hideContactInfo displayNewsLetter />

      <ScrollToTop />
    </div>
  );
};

export default Contact;
