import React from "react";
import "./GIT.css";
import { Link } from "react-scroll";

const GIT = (props) => {
  return (
    <div className="GIT-container">
      <div className="social-container">
        <ul className="social-container_content">
          <li>
            <span className="title">Suivez-nous</span>
          </li>
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61559890747854"
              target="_blank"
              className="site-button-link facebook hover"
            >
              <i className="fa fa-facebook"></i>
              <span> facebook</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/casa_del_padel"
              target="_blank"
              className="site-button-link instagram hover"
            >
              <i className="fa fa-instagram"></i>
              <span> instagram</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="form-container ">
        <div className="text-content">
          <h1 className="GIT-title">Contactez Nous</h1>
          <p className="GIT-p" style={{ maxWidth: "none" }}>
            N'hésitez pas à nous envoyer un e-mail.
          </p>
          <form className="GIT-form">
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                className="GIT-input"
                placeholder="Votre nom"
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                id="email"
                className="GIT-input"
                name="email"
                placeholder="Votre adresse e-mail"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                id="Subject"
                className="GIT-input"
                name="Subject"
                placeholder="Sujet"
              />
            </div>
            <div className="form-group">
              <textarea
                name="Text1"
                cols="40"
                className="GIT-input"
                rows="8"
                placeholder="Contenu de l'e-mail..."
              ></textarea>
            </div>

            <button type="submit" class="GIT-btn">
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GIT;
