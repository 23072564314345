import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import swDev from "./PWA/swDev";

import * as serviceWorker from "./PWA/serviceWorker";
import { Provider } from "react-redux";

import store from "./Store/index";
import { persistor } from "./Store/index";
import { PersistGate } from "redux-persist/integration/react";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// reportWebVitals();

swDev();
