import React, { useEffect } from "react";
import ScrollToTop from "../../Shared/ScrollToTop";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";
import "./Home.css";
import { Button } from "react-bootstrap";
import Services from "./components/Services/Services";
import TournoisListe from "./components/TournoisListe/TournoisListe";
import { useDispatch } from "react-redux";
import { getTournaments } from "../../../Store/tournametsSlice";
import { getPacks } from "../../../Store/packSlice";
import Packs from "./components/Packs/Packs";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getTournaments());
    dispatch(getPacks());
  }, []);
  return (
    <>
      <Header />
      <div className="bg-container">
        <div className="bg-container_content">
          <div className="text-12 text-brightness">
            Rejoignez-nous pour une expérience de jeu inoubliable.
          </div>
          <br></br>
          <button
            // style={{ marginTop: "25px" }}
            className="start-btn1 text-brightness primary-btn"
            onClick={() => navigate("/reservation")}
          >
            RÉSERVE TON TERRAIN EN LIGNE
          </button>
        </div>
      </div>
      <div className="container" style={{ marginTop: "3%" }}>
        <div className="md-container">
          <div className="text-2">Les Triomphes Récents</div>
          <div className="text-2">Retour sur Nos Derniers Tournois!</div>
          <div className="divider"></div>
          <TournoisListe />

          <div className="divider"></div>
          <div
            className="text-2 mb-20"
            style={{ marginTop: "3%", marginBottom: "5%" }}
          >
            Économisez en Jouant
            <br></br>
            Découvrez Nos Packs de Jetons
          </div>
          <Packs />
          <div className="services">
            <div className="text-2" style={{ marginTop: "50px" }}>
              Découvrez Nos Services
            </div>
            <Services />
          </div>
        </div>
      </div>
      <Footer hideContactInfo displayNewsLetter />

      <ScrollToTop />
    </>
  );
};

export default Home;
