import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectUser } from "./Store/userSlice";

const ProtectedRoutes = () => {
  const user = useSelector(selectUser);

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
