import React from "react";
import ScrollToTop from "../../Shared/ScrollToTop";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";
import image_not_found from "../../../assets/images/404_not_found.png";

import "./Error.css";
import { NavLink } from "react-router-dom";

const ErrorNotFound = () => {
  return (
    <>
      <Header />
      <div class="bg-image"></div>
      <div class="bg-text">
        <h1>Page non trouvée</h1>
        <div className="start-btn1 text-brightness primary-btn">
          <NavLink to="/">Retour à la page d'accueil</NavLink>
        </div>
      </div>
      <Footer hideContactInfo displayNewsLetter />
      <ScrollToTop />
    </>
  );
};

export default ErrorNotFound;
