import http from "./Httpcommon";
class TournoisService {
  getAllTournaments() {
    return http.get("/tournaments/all");
  }
  getTournament(id) {
    return http.get("/tournaments/" + id);
  }

  subscribeToTournament(body) {
    return http.post("/tournaments/subscribe", body);
  }
}
export default new TournoisService();
