import React from 'react'
import "./Support.css"


const Support = (props) => {
    return (
        <div className='big-container'>
            <div className='img-container'>

            </div>
            <div className='text-container '>
                <div className='text2'>
                    <h2>Les bases du padel</h2>
                    <div className='separator'></div>
                    <div className='content'>
                        <ul>
                            <li>Padel est un sport de raquette qui combine les éléments du tennis et du squash. Il se joue en double sur un court plus petit que celui du tennis, entouré de murs de verre et de grillages.</li>
                            <li>Les joueurs utilisent des raquettes spécifiques de padel, qui sont solides et sans cordes, et une balle similaire mais légèrement plus douce que celle du tennis.</li>
                            <li>Le jeu se déroule en deux sets gagnants. Le service s'effectue sous la taille et le rebond de la balle sur le mur est autorisé, ce qui crée une dynamique de jeu unique.</li>
                            <li>Le padel met l'accent sur la stratégie et la technique plutôt que sur la puissance. La communication entre les partenaires et la capacité à jouer des coups variés sont cruciales pour le succès.</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Support;