import React, { useState, useRef } from "react";
import Header from "../../layout/Header/Header";
import { ToastContainer, toast } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import login_bg from "../../../assets/images/login_bg.jpg";
import { useDispatch, useSelector } from "react-redux";
import { ForgotUserPassword } from "../../../Store/userSlice";

const ForgotPass = () => {
  const [email, setEmail] = useState("");

  const loading = useSelector((state) => state?.loading);

  const alert = (type, response) => {
    if (type === "success") {
      toast.success(
        "l'e-mail pour réinitialiser le mot de passe a été envoyé avec succès",
        {
          autoClose: 5000,
          theme: "light",
        }
      );
    } else {
      if (Array.isArray(response.data.message)) {
        response.data.message.forEach((msg) => {
          toast.error(msg, {
            autoClose: 5000,
            theme: "light",
          });
        });
      } else {
        toast.error(response.data.message, {
          autoClose: 5000,
          theme: "light",
        });
      }
    }
  };

  const form = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subimtHandler = (e) => {
    e.preventDefault();

    if (email == "") {
      alert("error", { data: { message: "l'adresse mail n'est pas valide" } });
      return;
    }

    dispatch(ForgotUserPassword(email)).then((result) => {
      console.log(result.payload);
      if (result.payload.status == 200) {
        alert("success", result.payload);
        setEmail("");
      } else {
        alert("error", result.payload);
      }
    });
  };

  return (
    <>
      <Header />
      <ToastContainer position="top-right" theme="light" />
      <img className="bg_img" src={login_bg} alt="bg" />

      <div className="login-container ">
        <div className="d-flex justify-content-center">
          <h1 style={{ fontSize: "1.6rem" }} className="login-title">
            réinitialiser le mot de passe
          </h1>
        </div>
        <form className="login-form" ref={form} onSubmit={subimtHandler}>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Entrez votre adresse e-mail"
            />
          </div>

          <NavLink to="/login">
            <p>Déjà inscrit ? Connectez-vous ici !</p>
          </NavLink>

          <div className="d-flex justify-content-center">
            <button type="submit" className="primary-btn login-btn">
              {loading ? "Loading..." : "Réinitialiser"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPass;
