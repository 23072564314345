import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectUser } from "./Store/userSlice";

const LoginProtectedRoutes = () => {
  const user = useSelector(selectUser);

  return user ? <Navigate to="/" /> : <Outlet />;
};

export default LoginProtectedRoutes;
