import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaCoins } from "react-icons/fa";
import Avatar from "@mui/material/Avatar";
import "./UserDropdown.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import UserService from "../../../../api/UserService";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, selectUser } from "../../../../Store/userSlice";
import { ToastContainer, toast } from "react-toastify";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise  */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
const UserDropdown = () => {
  // const [user, setUser] = useState({});

  const user = useSelector(selectUser);

  const [lastname, setLastname] = useState(user.lastname);
  const [firstname, setFirstname] = useState(user.firstname);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const logout = async () => {
    dispatch(logoutUser()).then((result) => {});

    window.location.assign("/login");
  };
  const toggle = () => {
    setModal(!modal);
  };

  // Added validation function
  const validateForm = () => {
    let errors = [];
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{8}$/; // Phone number must be 8 digits
    const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/; // Password validation
    const namePattern = /^[A-Za-z\s]+$/; // Name validation

    if (!firstname.trim()) errors.push("Le prénom est obligatoire.");
    else if (!namePattern.test(firstname))
      errors.push("Le prénom doit contenir uniquement des lettres.");

    if (!lastname.trim()) errors.push("Le nom de famille est obligatoire.");
    else if (!namePattern.test(lastname))
      errors.push("Le nom de famille doit contenir uniquement des lettres.");

    if (!emailPattern.test(email)) errors.push("Adresse e-mail invalide.");
    if (!phonePattern.test(phone))
      errors.push("Le numéro de téléphone doit comporter 8 chiffres.");
    if (password && !passwordPattern.test(password))
      errors.push(
        "Le mot de passe doit comporter au moins 6 caractères et inclure au moins une lettre et un chiffre."
      );

    if (errors.length > 0) {
      errors.forEach((error) =>
        toast.error(error, { autoClose: 5000, theme: "light" })
      );
      return false;
    }

    return true;
  };

  const editProfile = async () => {
    // Check validation
    if (!validateForm()) return;
    let token = JSON.parse(localStorage.getItem("token")).accessToken;

    let body = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      id: user._id,
      password: password,
    };
    await UserService.editProfile(token, body)
      .then(async (response) => {
        console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
    // window.location.reload(false);

    toggle();
  };
  useEffect(() => {
    setLastname(user.lastname);
    setFirstname(user.firstname);
    setEmail(user.email);
    setPhone(user.phone);
  }, []);

  return (
    <>
      <Dropdown>
        <div className="extra-nav">
          <div
            className="extra-cell has-mega-menu"
            id="dropdown-custom-components"
          >
            <Dropdown.Toggle id="dropdown-custom-components">
              <Avatar
                className="avatar"
                alt={user.firstname}
                src={user.picture}
              />
              {/* <Avatar className="avatar" {...stringAvatar()} /> */}

              <div className="user-name">
                {user.firstname} {user.lastname}{" "}
              </div>
            </Dropdown.Toggle>
          </div>
          <Dropdown.Menu>
            {/* <div className="menu-div-name">
              <Avatar alt={user.firstname} src={user.picture} />
              <div className="user-name">
                {user.firstname} {user.lastname}{" "}
              </div>
            </div> */}
            <div className="menu-div-body">
              <i
                className="fa fa-pencil edit_profile"
                aria-hidden="true"
                onClick={toggle}
              ></i>
              {/* <Avatar
                className="avatar"
                {...stringAvatar('Ramos Ben Aribia')}
              /> */}
              <Avatar
                className="avatar"
                alt={user.firstname}
                src={user.picture}
              />

              <h6>
                {user.firstname} {user.lastname}
              </h6>
              {/* <p>#{user._id}</p> */}
              <div className="coins">
                {user.jetons}
                <FaCoins />
              </div>
              {/* <h6 style={{ padding: "0px 18px" }}>{user.email} </h6> */}
              <h6>{user.phone} </h6>
              <button className="primary-btn" onClick={logout}>
                Se déconnecter
              </button>
            </div>
          </Dropdown.Menu>
        </div>
      </Dropdown>

      <Dialog open={modal} onClose={toggle}>
        <DialogTitle id="draggable-dialog-title">
          Modifier votre profil
        </DialogTitle>
        <DialogContent>
          <div>
            <FormControl fullWidth>
              <ToastContainer position="top-right" theme="light" />
              {/* <InputLabel id="demo-simple-select-label">sJetons</InputLabel> */}
              <div className="custom-break-line-1"></div>
              <TextField
                required
                id="outlined-required"
                label="Nom"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
              <div className="custom-break-line-2"></div>
              <TextField
                required
                id="outlined-required"
                label="Prénom"
                value={firstname}
                defaultValue={user.firstName}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <div className="custom-break-line-2"></div>
              <TextField
                required
                id="outlined-required"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="custom-break-line-2"></div>
              <TextField
                id="outlined-required"
                label="téléphone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <div className="custom-break-line-2"></div>
              <div className="form-group pwd_group">
                <input
                  id="outlined-required"
                  label="mot de passe"
                  type={passwordType}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  placeholder="mot de passe"
                />

                <div className="input-group-btn">
                  <button className="btn" onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </button>
                </div>
              </div>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="modal-footer">
          <>
            <Button color="secondary" onClick={toggle}>
              Annuler
            </Button>

            <Button className="primary-btn" onClick={editProfile}>
              Enregistrer
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDropdown;
