import React, { useEffect, useState } from "react";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { FaCoins } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";

import "@fullcalendar/daygrid/main.css";
import { toast } from "react-toastify";

import ReservationService from "../../../../api/ReservationService";

import "./EditReservationPopUp.css";
import { useDispatch, useSelector } from "react-redux";
import { refreshUserUser, selectUser } from "../../../../Store/userSlice";

const EditReservationPopUp = ({
  modal,
  toggle,
  selectedEvent,
  handleChange,
  terrain,
  terrainNames,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [payementMethod, setPayementMethod] = useState("jetons");
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [terrainName, setTerrainName] = useState("");

  const [jetons, setjetons] = useState(1);
  const [numberOfplaces, setNumberOfplaces] = useState(1);

  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [cancellationProhibited, setCancellationProhibited] = useState(false);
  const [playerAdditionProhibited, setPlayerAdditionProhibited] =
    useState(false);

  const alert = (response) => {
    if (response.status === 201) {
      toast.success("votre réservation a été modifiée avec succès", {
        autoClose: 5000,
        theme: "light",
      });
    } else {
      toast.error(response.data.message, {
        autoClose: 5000,
        theme: "light",
      });
    }
  };

  const availablePlaces = () => {
    let content = [];
    for (let i = 1; i <= selectedEvent.availablePlaces; i++) {
      content.push(<MenuItem value={i}>{i}</MenuItem>);
    }
    return content;
  };

  const editEventClick = async () => {
    setButtonDisabled(true);
    let token = JSON.parse(localStorage.getItem("token")).accessToken;
    let body = {
      reservationId: selectedEvent._id,
      howMuchPaid: payementMethod === "cash" ? 0 : Number(jetons),
      restByCash: payementMethod === "cash",
      bycash: payementMethod === "cash",
      numberOfplaces: payementMethod === "cash" ? Number(numberOfplaces) : 0,
    };

    await ReservationService.editResrevation(token, body)
      .then((response) => {
        alert(response);
        handleChange(null, terrain);
        dispatch(refreshUserUser()).then((result) => {});
        toggle();
      })
      .catch((e) => {
        alert(e.response);
      });
    setButtonDisabled(false);
  };

  const cancelEventBooking = async (cancel_type) => {
    setButtonDisabled(true);

    let token = JSON.parse(localStorage.getItem("token")).accessToken;

    let body = {
      reservationId: selectedEvent._id,
      cancel_type: cancel_type,
    };

    await ReservationService.cancelReservationBooking(token, body)
      .then((response) => {
        alert(response);
        handleChange(null, terrain);
        dispatch(refreshUserUser()).then((result) => {});

        toggle();
      })
      .catch((e) => {
        alert(e.response);
      });
    setButtonDisabled(false);
  };

  const cancelEventClick = async () => {
    setButtonDisabled(true);

    let token = JSON.parse(localStorage.getItem("token")).accessToken;

    let body = {
      reservationId: selectedEvent._id,
    };

    await ReservationService.cancelReservation(token, body)
      .then((response) => {
        alert(response);
        handleChange(null, terrain);
        dispatch(refreshUserUser()).then((result) => {});

        toggle();
      })
      .catch((e) => {
        alert(e.response);
      });
    setButtonDisabled(false);
  };

  useEffect(() => {
    if (modal) {
      const today = new Date(selectedEvent.start);
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      var time = today.getHours() + ":" + today.getMinutes();
      if (today.getMinutes() === 0) {
        time = time + "0";
      }
      setTime(time);
      setDate(date);

      const cancellationLimit = new Date();
      const playerAdditionLimit = new Date();
      cancellationLimit.setDate(cancellationLimit.getDate() + 1);

      setCancellationProhibited(
        new Date(selectedEvent.start).getTime() < cancellationLimit.getTime()
      );
      setPlayerAdditionProhibited(
        new Date(selectedEvent.start).getTime() < playerAdditionLimit.getTime()
      );

      const terrainObj = terrainNames.find(
        (terrain) => terrain._id === selectedEvent.terrain
      );
      setTerrainName(terrainObj.name);
      setjetons(1);
      setNumberOfplaces(1);
    }
  }, [modal]);

  return (
    <>
      <Dialog open={modal} onClose={toggle}>
        <DialogTitle id="draggable-dialog-title">Réserver</DialogTitle>
        <DialogContent>
          <div>
            <div className="edit_details">
              <h5>Date :</h5>
              <p>{date} </p>
            </div>
            <div className="edit_details">
              <h5>Heure :</h5>
              <p>{time} </p>
            </div>
            <div className="edit_details">
              <h5>Terrain :</h5>
              <p>{terrainName} </p>
            </div>
            <h5>Réservé par : </h5>
            <div>
              <ul>
                {selectedEvent.players.map((player, index) => (
                  <div key={index}>
                    {player.nombreOfJetonsPayed === 0 ? (
                      <></>
                    ) : (
                      <>
                        <li>
                          {player.userFullName}: {player.nombreOfJetonsPayed}{" "}
                          <FaCoins />
                          {player.userId === user._id &&
                          !cancellationProhibited ? (
                            <>
                              {isButtonDisabled ? (
                                <CircularProgress
                                  size={"1rem"}
                                  className="CircularProgress_cancelBooking"
                                  style={{
                                    marginLeft: "6px",
                                    marginBottom: "-3px",
                                  }}
                                />
                              ) : (
                                <button
                                  className="cancel_booking"
                                  onClick={() => cancelEventBooking("jetons")}
                                >
                                  (<FcCancel />
                                  annuler mes jetons)
                                </button>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      </>
                    )}
                    {player.numberOfPlacesByCash === 0 ? (
                      <></>
                    ) : (
                      <>
                        <li>
                          {player.userFullName}: {player.numberOfPlacesByCash}{" "}
                          places réservées en espèces
                          {player.userId === user._id &&
                          !cancellationProhibited ? (
                            <>
                              {isButtonDisabled ? (
                                <CircularProgress
                                  size={"1rem"}
                                  className="CircularProgress_cancelBooking"
                                  style={{
                                    marginLeft: "6px",
                                    marginBottom: "-3px",
                                  }}
                                />
                              ) : (
                                <button
                                  className="cancel_booking"
                                  // onClick={cancelEventBooking}
                                  onClick={() => cancelEventBooking("places")}
                                >
                                  (<FcCancel />
                                  annuler mes places)
                                </button>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      </>
                    )}
                  </div>
                ))}
              </ul>
            </div>

            {selectedEvent.availablePlaces === 0 || playerAdditionProhibited ? (
              <></>
            ) : (
              <>
                <h5>Payer le reste en espèces ou en jetons : </h5>
                <Tabs
                  value={payementMethod}
                  onChange={(e, payementMethod) =>
                    setPayementMethod(payementMethod)
                  }
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="jetons" label="jetons" />
                  <Tab value="cash" label="espèces" />
                </Tabs>
                {payementMethod === "jetons" ? (
                  <>
                    <h5>Ajouter jeton: </h5>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Jetons
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={jetons}
                        label="Terrain"
                        onChange={(e) => setjetons(e.target.value)}
                      >
                        {availablePlaces()}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <h5>sélectionner le nombre de places: </h5>

                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Nombre de places
                      </InputLabel>
                      <Select
                        labelId="test-select-label"
                        id="demo-simple-select"
                        value={numberOfplaces}
                        label="Nombre de places"
                        onChange={(e) => setNumberOfplaces(e.target.value)}
                      >
                        {availablePlaces()}
                      </Select>
                    </FormControl>
                  </>
                )}
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className="modal-footer">
          {selectedEvent.availablePlaces === 0 || playerAdditionProhibited ? (
            <>
              {selectedEvent.createdBy === user._id &&
              !cancellationProhibited ? (
                <>
                  <Button
                    className="annulation-btn"
                    onClick={cancelEventClick}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? (
                      <CircularProgress size={"1rem"} />
                    ) : (
                      "Annuler ma réservation"
                    )}
                  </Button>
                </>
              ) : (
                <></>
              )}
              <Button color="secondary" onClick={toggle}>
                {" "}
                OK{" "}
              </Button>
            </>
          ) : (
            <>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>
              {selectedEvent.createdBy === user._id &&
              !cancellationProhibited ? (
                <>
                  <Button
                    className="annulation-btn"
                    onClick={cancelEventClick}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? (
                      <CircularProgress size={"1rem"} />
                    ) : (
                      "Annuler ma réservation"
                    )}
                  </Button>
                </>
              ) : (
                <></>
              )}
              <Button
                className="primary-btn"
                onClick={editEventClick}
                disabled={isButtonDisabled}
              >
                {isButtonDisabled ? (
                  <CircularProgress size={"1rem"} />
                ) : (
                  "Ajouter"
                )}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditReservationPopUp;
