import React, { useRef, useState } from "react";
import login_bg from "../../../assets/images/login_bg.jpg";
import "./Login.css";

import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Header from "../../layout/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../Store/userSlice";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loading = useSelector((state) => state?.loading);

  const [passwordType, setPasswordType] = useState("password");

  const alert = (type, response) => {
    if (type === "success") {
      toast.success("Connecté avec succès ", {
        autoClose: 5000,
        theme: "light",
      });
    } else {
      if (Array.isArray(response.data.message)) {
        response.data.message.forEach((msg) => {
          toast.error(msg, {
            autoClose: 5000,
            theme: "light",
          });
        });
      } else {
        toast.error(response.data.message, {
          autoClose: 5000,
          theme: "light",
        });
      }
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const form = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subimtHandler = (e) => {
    e.preventDefault();

    const body = {
      email,
      password,
    };

    dispatch(loginUser(body)).then((result) => {
      if (result.payload.status) {
        alert("error", result.payload);
      } else {
        alert("success", result);

        setEmail("");
        setPassword("");
        navigate("/");
      }
    });
  };

  return (
    <>
      <Header />
      <ToastContainer position="top-right" theme="light" />

      <img className="bg_img" src={login_bg} alt="bg" />

      <div className="login-container ">
        <div className="d-flex justify-content-center">
          <h1 className="login-title">Connexion</h1>
        </div>
        <form className="login-form" ref={form} onSubmit={subimtHandler}>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Entrez votre adresse e-mail"
            />
          </div>

          <div className="form-group pwd_group">
            <input
              type={passwordType}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Entrez votre mot de passe"
            />

            <div className="input-group-btn">
              <button className="btn" type="button" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <AiOutlineEye />
                ) : (
                  <AiOutlineEyeInvisible />
                )}
              </button>
            </div>
          </div>
          <NavLink to="/register">
            <p className="register_btn">Pas encore de compte ?</p>
          </NavLink>

          <NavLink to="/forgot-pass">
            <p>mot de passe oublié ?</p>
          </NavLink>

          <div className="d-flex justify-content-center">
            <button type="submit" className="primary-btn login-btn">
              {loading ? "Loading..." : "Se connecter"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
