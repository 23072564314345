import React, { useRef, useState } from "react";
import "./Register.css";
import Radio from "@mui/material/Radio";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { NavLink, useNavigate } from "react-router-dom";
import { FormControlLabel, RadioGroup } from "@mui/material";
import Header from "../../layout/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../Store/userSlice";
import { ToastContainer, toast } from "react-toastify";
import login_bg from "../../../assets/images/login_bg.jpg";

const Register = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const alert = (type, response) => {
    if (type === "success") {
      toast.success("Connecté avec succès ", {
        autoClose: 5000,
        theme: "light",
      });
    } else {
      if (Array.isArray(response.data.message)) {
        response.data.message.forEach((msg) => {
          toast.error(msg, {
            autoClose: 5000,
            theme: "light",
          });
        });
      } else {
        toast.error(response.data.message, {
          autoClose: 5000,
          theme: "light",
        });
      }
    }
  };

  const form = useRef();
  const loading = useSelector((state) => state?.loading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Added validation function
  const validateForm = () => {
    let errors = [];
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{8}$/; // Updated phone validation to 8 digits
    const passwordPattern = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/; // Changed password validation
    const namePattern = /^[A-Za-z\s]+$/; // Added name validation

    if (!firstname.trim()) errors.push("Le prénom est obligatoire.");
    else if (!namePattern.test(firstname))
      errors.push("Le prénom doit contenir uniquement des lettres.");

    if (!lastname.trim()) errors.push("Le nom de famille est obligatoire.");
    else if (!namePattern.test(lastname))
      errors.push("Le nom de famille doit contenir uniquement des lettres.");

    if (!emailPattern.test(email)) errors.push("Adresse e-mail invalide.");
    if (!phonePattern.test(phone))
      errors.push("Le numéro de téléphone doit comporter 8 chiffres.");
    if (!passwordPattern.test(password))
      errors.push(
        "Le mot de passe doit comporter au moins 6 caractères et inclure au moins une lettre et un chiffre."
      );
    if (!gender) errors.push("Le genre est obligatoire.");

    if (errors.length > 0) {
      errors.forEach((error) =>
        toast.error(error, { autoClose: 5000, theme: "light" })
      );
      return false;
    }

    return true;
  };

  const subimtHandler = (e) => {
    e.preventDefault();

    // Validation
    // Check validation
    if (!validateForm()) return;

    const register_body = {
      firstname,
      lastname,
      password,
      phone,
      email,
      gender,
    };

    const login_body = {
      email,
      password,
    };

    dispatch(registerUser(register_body, login_body)).then((result) => {
      if (result.payload.status) {
        alert("error", result.payload);
      } else {
        alert("success", result);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setPhone("");
        setGender("");
        navigate("/");
        navigate("/");
      }
    });
  };

  return (
    <>
      <Header />
      <ToastContainer position="top-right" theme="light" />
      <img className="bg_img" src={login_bg} alt="bg" />

      <div className="register-container">
        <div className="d-flex justify-content-center">
          <h1 className="register-title_re">S'inscrire</h1>
        </div>
        <form className="register-form" ref={form} onSubmit={subimtHandler}>
          <div className="form-group">
            <input
              type="text"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
              className="form-control"
              placeholder="Entrez votre prénom"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              className="form-control"
              placeholder="Entrez votre nom de famille"
            />
          </div>

          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              placeholder="Entrez votre adresse e-mail"
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-control"
              placeholder="Entrez votre numéro de téléphone"
            />
          </div>

          <div style={{ marginTop: "0px" }} className="form-group pwd_group">
            <input
              type={passwordType}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Entrez votre mot de passe"
            />

            <div className="input-group-btn">
              <button className="btn" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <AiOutlineEye />
                ) : (
                  <AiOutlineEyeInvisible />
                )}
              </button>
            </div>
          </div>
          <div className="form-group">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => setGender(e.target.value)}
            >
              <FormControlLabel
                value="h"
                control={<Radio />}
                label="Homme"
                color="red"
              />
              <FormControlLabel value="f" control={<Radio />} label="Femme" />
            </RadioGroup>
          </div>
          <NavLink to="/login">
            <p className="">Déjà inscrit ? Connectez-vous ici !</p>
          </NavLink>

          {/* <div className=" form-check">
              <Radio />
              <label className="form-check-label" for="exampleCheck1">
                I agree to all <a href="/casa">terms and conditions</a>
              </label>
            </div> */}
          <div className="d-flex justify-content-center">
            <button type="submit" className="primary-btn register-btn">
              {loading ? "Loading..." : "S'inscrire"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
