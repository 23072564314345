import React from "react";
import Header from "../../layout/Header/Header";
import { ToastContainer } from "react-toastify";
import login_bg from "../../../assets/images/login_bg.jpg";

const InvalidToken = () => {
  return (
    <>
      <Header />
      <ToastContainer position="top-right" theme="light" />
      <img className="bg_img" src={login_bg} alt="bg" />
      <div className="login-container ">
        <div className="d-flex justify-content-center">
          <h1
            style={{ fontSize: "1rem", marginBottom: "2rem" }}
            className="login-title"
          >
            Veuillez répéter le processus pour changer votre mot de passe.
          </h1>
        </div>
      </div>
    </>
  );
};

export default InvalidToken;
