import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Sticky from "react-stickynode";

import "./Header.css";

import logo from "../../../assets/images/logo.png";
import UserDropdown from "./components/UserDropdown";
// import { useAddToHomescreenPrompt } from "../../../PWA/AddToHomeScreen";
import { useSelector } from "react-redux";
import { selectUser } from "../../../Store/userSlice";

const Header = () => {
  const [active, setActive] = useState("");
  const user = useSelector(selectUser);
  const { pathname } = useLocation();

  // const [isAppInstalled, setIsAppInstalled] = useState(false);
  // const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  // function getPWADisplayMode() {
  //   const isStandalone = window.matchMedia(
  //     "(display-mode: standalone)"
  //   ).matches;
  //   if (document.referrer.startsWith("android-app://")) {
  //     setIsAppInstalled(true);
  //   } else if (navigator.standalone || isStandalone) {
  //     setIsAppInstalled(false);
  //   } else {
  //     setIsAppInstalled(true);
  //   }
  // }
  const handleStateChange = (status) => {
    const fixed = status.status === Sticky.STATUS_FIXED ? true : false;
    const isSpecialPath =
      /^(\/login|\/register|\/forgot-pass|\/invalid-token|\/reset-password)$/.test(
        pathname
      );

    if (isSpecialPath) {
      setActive("is-fixed");
    } else {
      if (fixed === true) {
        setActive("is-fixed");
      } else {
        setActive("");
      }
    }
  };

  function toggleFunc() {
    var btn = document.querySelector(".navicon");
    var nav = document.querySelector(".header-nav");
    btn.classList.toggle("open");
    nav.classList.toggle("show");
  }

  useEffect(() => {
    // sidebar open/close
    // getPWADisplayMode();

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".header-nav > ul > li")
    );
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this);
      });
    }
    // set the bar fix with white bg
    const isSpecialPath =
      /^(\/login|\/register|\/forgot-pass|\/invalid-token|\/reset-password)$/.test(
        pathname
      );

    if (isSpecialPath) {
      setActive("is-fixed");
    }

    function checkLi(current) {
      const active = current.classList.contains("open");
      navUl.forEach((el) => el.classList.remove("open"));
      //current.classList.add('open');

      if (active) {
        current.classList.remove("open");
      } else {
        current.classList.add("open");
      }
    }
  }, []);

  return (
    <>
      <Sticky innerZ={999} enabled={true} onStateChange={handleStateChange}>
        <header className="site-header mo-left header header-transparent navstyle2">
          <div
            className={`sticky-header main-bar-wraper ${active} navbar-expand-lg`}
          >
            <div className="main-bar clearfix ">
              <div className="container clearfix">
                <div className="logo-header mostion">
                  <NavLink to="/">
                    <img src={logo} alt="" />
                  </NavLink>
                </div>

                <div className="content-nav">
                  <button
                    className="navbar-toggler collapsed navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={toggleFunc}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  {user ? <UserDropdown /> : <></>}

                  <div
                    className="header-nav navbar-collapse collapse justify-content-end"
                    id="navbarNavDropdown"
                  >
                    <div className="logo-header d-md-block d-lg-none">
                      <NavLink to="/">
                        <img src={logo} alt="" />
                      </NavLink>
                    </div>

                    <ul className="nav navbar-nav">
                      <li className=" has-mega-menu homedemo">
                        <NavLink to="/">Accueil</NavLink>
                      </li>
                      <li className="has-mega-menu">
                        <NavLink to="/reservation">Reservation</NavLink>
                      </li>
                      <li>
                        <NavLink to="/tournois">Tournois</NavLink>
                      </li>
                      <li className="has-mega-menu">
                        {" "}
                        <NavLink to="/contact">Contactez-nous</NavLink>
                      </li>{" "}
                      {/* {!isAppInstalled ? ( */}
                      {/* <li className="has-mega-menu">
                        <button className="btn" onClick={promptToInstall}>
                          Install
                        </button>
                      </li> */}
                      {/* ) : (
                        <></>
                      )} */}
                      {user ? (
                        <></>
                      ) : (
                        <>
                          <li className="has-mega-menu">
                            {" "}
                            <NavLink to="/login">Connexion</NavLink>
                          </li>{" "}
                          <li className="has-mega-menu">
                            {" "}
                            <NavLink to="/register">S'inscrire</NavLink>
                          </li>
                        </>
                      )}
                    </ul>
                    <div className="dlab-social-icon">
                      <ul>
                        <li>
                          <NavLink
                            className="site-button sharp-sm fa fa-facebook"
                            to="/javascript:void(0);"
                          ></NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="site-button sharp-sm fa fa-twitter"
                            to="/javascript:void(0);"
                          ></NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="site-button sharp-sm fa fa-linkedin"
                            to="/javascript:void(0);"
                          ></NavLink>
                        </li>
                        <li>
                          <NavLink
                            className="site-button sharp-sm fa fa-instagram"
                            to="/javascript:void(0);"
                          ></NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Sticky>
    </>
  );
};

export default Header;
