import { combineReducers } from "@reduxjs/toolkit";
import user from "./userSlice";
import tournaments from "./tournametsSlice";
import packs from "./packSlice";
import reservations from "./reservationsSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    user,
    tournaments,
    // reservations,
    packs,

    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  // if (action.type === "user/userLoggedOut") {
  //   // state = undefined;
  // }

  return combinedReducer(state, action);
};

export default createReducer;
