import { Card, Button } from "react-bootstrap";

import "./TournoisListe.css";
import { useSelector } from "react-redux";
import { selectFirstTournaments } from "../../../../../Store/tournametsSlice";
import { NavLink, useNavigate } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TournoisListe() {
  const navigate = useNavigate();
  const tournaments = useSelector(selectFirstTournaments);
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 9000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      {tournaments.length < 1 ? (
        <div className="pas_de_tournois">
          <h1>🎾 Aucun tournoi en cours pour le moment ! 🏆 </h1>
        </div>
      ) : (
        <>
          <Slider
            {...settings}
            className="img-carousel-dots-nav btn-style-1 icon-2 container-fluid text-center"
          >
            {tournaments.map((tournament, index) => (
              <Card key={index} className="tournament_card">
                <Card.Body>
                  <Card.Title>{tournament.title} </Card.Title>
                  <Card.Img
                    variant="top"
                    src={tournament.picture}
                    style={{ marginBottom: "10px" }}
                  />
                  <button
                    className="col-12 primary-btn"
                    onClick={() =>
                      navigate("/tournois/event/" + tournament._id)
                    }
                  >
                    En Savoir Plus
                  </button>
                </Card.Body>
              </Card>
            ))}
          </Slider>
          <div className="decouvrir_tournois">
            <NavLink to="/tournois">Voir Tous les Tournois</NavLink>
          </div>
        </>
      )}
    </>
  );
}
