import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import terrain from "../../../../../assets/icons/terrain.png";
import cafeteria from "../../../../../assets/icons/cafeteria.png";
import padel from "../../../../../assets/icons/padel.png";
import boutique from "../../../../../assets/icons/boutique.png";
import "./Services.css";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
// }));

export default function Services() {
  return (
    <Box
      sx={{ width: "100%" }}
      style={{ marginTop: "80px" }}
      className="services_container"
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <div className="row" style={{ marginBottom: "5%" }}>
          <Grid item xs={12} sm={6} style={{ marginBottom: "5%" }}>
            <Grid container>
              <Grid item xs={6} style={{ marginTop: "-10%" }}>
                <div className="content">
                  <div className="tit">
                    4 Terrains
                  </div>

                  <div className="parag">
                    Plongez dans l'action sur nos 4 terrains de padel dernière
                    génération, prêts à accueillir vos moments de victoire
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <img src={terrain} alt="terrain" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={6}>
                <img src={padel} alt="coach" />
              </Grid>

              <Grid item xs={6} style={{ marginTop: "-10%" }}>
                <div className="content">
                  <div className="tit">
                    Coaching
                  </div>

                  <div className="parag">
                    Élevez votre jeu avec l'expertise de nos coachs, prêts à
                    guider chaque coup pour votre succès.
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className="row" style={{ marginBottom: "5%" }}>
          <Grid item xs={12} sm={6} style={{ marginBottom: "5%" }}>
            <Grid container>
              <Grid item xs={6}>
                <div className="content">
                  <div className="tit">
                    Boutique
                  </div>

                  <div className="parag">
                    Explorez notre boutique pour trouver l'équipement parfait,
                    car chaque joueur mérite le meilleur.
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <img src={boutique} alt="boutique" />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid item xs={6} style={{}}>
                <img
                  src={cafeteria}
                  alt="entreprise"
                  style={{ marginLeft: "12px" }}
                />
              </Grid>

              <Grid item xs={6}>
                <div className="content">
                  <div className="tit">
                    Padel House
                  </div>

                  <div className="parag">
                    Détendez-vous après le match dans notre Club House
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Box>
  );
}
