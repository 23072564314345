import React, { useState, useRef, useEffect } from "react";
import Header from "../../layout/Header/Header";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import login_bg from "../../../assets/images/login_bg.jpg";
import { useDispatch, useSelector } from "react-redux";
import { ResetUserPassword } from "../../../Store/userSlice";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import axios from "axios";

const ResetPass = () => {
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const loading = useSelector((state) => state?.loading);

  const alert = (type, response) => {
    if (type === "success") {
      toast.success("le mot de passe a été changé envoyé avec succès", {
        autoClose: 5000,
        theme: "light",
      });
    } else {
      if (Array.isArray(response.data.message)) {
        response.data.message.forEach((msg) => {
          toast.error(msg, {
            autoClose: 5000,
            theme: "light",
          });
        });
      } else {
        toast.error(response.data.message, {
          autoClose: 5000,
          theme: "light",
        });
      }
    }
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const form = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subimtHandler = (e) => {
    e.preventDefault();

    if (password == "") {
      alert("error", { data: { message: "le mot de passe n'est pas valide" } });
      return;
    }

    const body = {
      newPassword: password,
      newPasswordToken: token,
    };

    dispatch(ResetUserPassword(body)).then((result) => {
      console.log(result.payload);
      if (result.payload.status == 201) {
        alert("success", result.payload);
        setPassword("");
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        alert("error", result.payload);
      }
    });
  };

  useEffect(() => {
    axios
      .get(`/users/reset-password/token-verify/${token}`)
      .then((res) => {
        // console.log("RESPONSE", res);
      })
      .catch((err) => {
        // console.log("ERROR", err);
        navigate("/invalid-token");
      });
  }, []);

  return (
    <>
      <Header />
      <ToastContainer position="top-right" theme="light" />
      <img className="bg_img" src={login_bg} alt="bg" />

      <div className="login-container ">
        <div className="d-flex justify-content-center">
          <h1 style={{ fontSize: "1.6rem" }} className="login-title">
            nouveau mot de passe
          </h1>
        </div>
        <form className="login-form" ref={form} onSubmit={subimtHandler}>
          <div className="form-group pwd_group">
            <input
              type={passwordType}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Entrez le nouveau mot de passe"
            />

            <div className="input-group-btn">
              <button className="btn" type="button" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <AiOutlineEye />
                ) : (
                  <AiOutlineEyeInvisible />
                )}
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button type="submit" className="primary-btn login-btn">
              {loading ? "Loading..." : "Réinitialiser"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPass;
