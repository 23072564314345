import React, { useEffect, useState } from "react";
import ScrollToTop from "../../Shared/ScrollToTop";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";
import "./Tournois.css";
import CircularProgress from "@mui/material/CircularProgress";
import TablePagination from "@mui/material/TablePagination";

import EventCard from "./components/EventCard/EventCard";
import { useDispatch, useSelector } from "react-redux";

import {
  getTournaments,
  selectLoading,
  selectTotalTournaments,
  selectTournaments,
} from "../../../Store/tournametsSlice";

const Tournois = () => {
  const dispatch = useDispatch();
  const tournaments = useSelector(selectTournaments);
  const loading = useSelector(selectLoading);
  const totalTournaments = useSelector(selectTotalTournaments);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [state, setState] = useState({
    list: tournaments,
    filterKey: "All",
  });

  const filterTournament = (filterKey, tournaments) => {
    return tournaments.filter((tournament) =>
      filterKey === "All" ? tournament : tournament.state === filterKey
    );
  };

  const getFilterSections = (tournaments) => {
    const filtersMap = {
      openForSubscription: "Ouvert à la souscription",
      closedForSubscription: "Fermé Pour l'abonnement",
      inProgress: "En cours",
      finished: "Terminé",
    };
    const filters = [{ id: "Tous", value: "All" }];

    tournaments.forEach((tournament) => {
      const { state } = tournament;
      if (!filters.some((filter) => filter.value === state)) {
        filters.push({
          id: filtersMap[state],
          value: state,
        });
      }
    });

    return filters;
  };

  function handleChangePage(event, value) {
    setPage(value);
    dispatch(getTournaments({ currentPage: value + 1, itemsPerPage }));
  }

  function handleChangeRowsPerPage(event) {
    setItemsPerPage(event.target.value);
    setPage(0);
    dispatch(getTournaments({ itemsPerPage: event.target.value }));
  }

  const FilterFunction = (filter, id) => {
    setState({ ...state, filterKey: filter });
    const filterButtons = document.getElementsByClassName("filter_btn");
    for (let i = 0; i < filterButtons.length; i++) {
      filterButtons[i].classList.remove("active");
    }
    document.getElementById(id).classList.add("active");
  };

  const { list, filterKey } = state;

  const filteredList = filterTournament(filterKey, list);
  const filters = getFilterSections(tournaments);

  useEffect(() => {
    // Fetch tournaments when the component mounts
    dispatch(getTournaments());
  }, []);

  useEffect(() => {
    // Update local state when tournaments change in the Redux store
    setState({ ...state, list: tournaments });
  }, [tournaments]);

  return (
    <>
      <Header />

      <div className="pb-3">
        <div className="tournaments_page_header">
          <h1 className="title title--h1 first-title title__separate">
            Tournois{" "}
          </h1>
        </div>
        {loading ? (
          <div className="spiner_div">
            <CircularProgress size={"10rem"} />
          </div>
        ) : tournaments.length < 1 ? (
          <div className="section-full content-inner-2  bg-gray">
            <div className="container">
              <div className="pas_de_tournois">
                <h1>
                  Nous travaillons activement pour vous proposer de passionnants
                  tournois de padel. Restez connectés, car de nouveaux
                  événements sont en préparation et seront bientôt annoncés ! 🚀
                </h1>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-block">
            <div className="section-full content-inner-2  text-uppercase bg-gray">
              <div className="container">
                <div className="site-filters clearfix center  m-b40">
                  {/* FILTERS */}
                  <ul className="filters" data-toggle="buttons">
                    {filters.map((filter, id) => (
                      <li
                        id={id}
                        key={id}
                        className={
                          id === 0 ? "btn filter_btn active" : "btn filter_btn "
                        }
                        onClick={() => FilterFunction(filter.value, id)}
                      >
                        <div className="site-button-secondry button-sm radius-xl">
                          <span>{filter.id}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div
                  className="container-fluid tournament_container"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {filteredList
                    .map((tournament, id) => (
                      <div key={id} data-aos="zoom-in">
                        <EventCard tournament={tournament} />
                      </div>
                    ))
                    .reverse()}
                </div>
              </div>
            </div>

            <TablePagination
              className="shrink-0 border-t-1 pagination_div"
              component="div"
              count={totalTournaments}
              rowsPerPage={itemsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "Previous Page",
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page",
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>

      <Footer hideContactInfo displayNewsLetter />

      <ScrollToTop />
    </>
  );
};

export default Tournois;
