/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../api/UserService";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userCredentials) => {
    try {
      const accesstoken_request = await UserService.login(userCredentials);

      const accesstoken = await accesstoken_request.data;
      localStorage.setItem("token", JSON.stringify(accesstoken));

      const user_request = await UserService.getuser(accesstoken);
      const response = await user_request.data;

      localStorage.setItem("user", JSON.stringify(response));
      return response;
    } catch (error) {
      // throw error; // Throw the error to be caught later
      return error.response;
    }
  }
);

export const ForgotUserPassword = createAsyncThunk(
  "user/ForgotUserPassword",
  async (email) => {
    try {
      const response = await UserService.ForgotPassword(email);
      return response;
    } catch (error) {
      // throw error; // Throw the error to be caught later
      return error.response;
    }
  }
);

export const ResetUserPassword = createAsyncThunk(
  "user/ResetUserPassword",
  async (body) => {
    try {
      const response = await UserService.ResetPassword(body);
      return response;
    } catch (error) {
      // throw error; // Throw the error to be caught later
      return error.response;
    }
  }
);

export const refreshUserUser = createAsyncThunk(
  "user/refreshUserUser",
  async () => {
    const accessToken = localStorage.getItem("token");

    try {
      const user_request = await UserService.getuser(accessToken);
      const response = await user_request.data;

      localStorage.setItem("user", JSON.stringify(response));
      return response;
    } catch (error) {}
  }
);

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (register_body) => {
    const userCredentials = {
      email: register_body.email,
      password: register_body.password,
    };
    try {
      const register_request = await UserService.register(register_body);

      // await UserService.register(register_body).then((response) => {
      //   console.log("response")
      //   console.log(response)

      // })
      // .catch((e) => {
      //   console.log("eee");
      //   console.log(e);
      // });
      // console.log("register_request");
      // console.log(register_request);

      const accesstoken_request = await UserService.login(userCredentials);

      const accesstoken = await accesstoken_request.data;
      localStorage.setItem("token", JSON.stringify(accesstoken));

      const user_request = await UserService.getuser(accesstoken);
      const response = await user_request.data;

      localStorage.setItem("user", JSON.stringify(response));

      return response;
    } catch (error) {
      // throw error; // Throw the error to be caught later
      return error.response;
    }
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  return dispatch(userLoggedOut());
};

const initialState = {
  loading: false,
  user: null,
  token: null,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.loading = false;
          state.error = action.payload.data.message;
        } else {
          state.loading = false;
          state.user = action.payload;
          state.error = null;
        }
      })
      .addCase(loginUser.rejected, (state, action, error) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(ForgotUserPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ForgotUserPassword.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.loading = false;
          state.error = action.payload.data.message;
        } else {
          state.loading = false;
          state.error = null;
        }
      })
      .addCase(ForgotUserPassword.rejected, (state, action, error) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(ResetUserPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ResetUserPassword.fulfilled, (state, action) => {
        console.log("action.payload", action.payload);
        if (action.payload.status) {
          state.loading = false;
          state.error = action.payload.data.message;
        } else {
          state.loading = false;
          state.error = null;
        }
      })
      .addCase(ResetUserPassword.rejected, (state, action, error) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.loading = false;
          state.error = action.payload.data.message;
        } else {
          state.loading = false;
          state.user = action.payload;
          state.error = null;
        }
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(refreshUserUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      });
  },
});

export const selectUser = ({ user }) => user.user;

export const { userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
