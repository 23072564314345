import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getTournaments = createAsyncThunk(
  "tournaments/getTournaments",
  (params = {}) => {
    const requestParams = {};
    if (params.itemsPerPage) {
      requestParams.itemsPerPage = params.itemsPerPage;
    }
    if (params.currentPage) {
      requestParams.currentPage = params.currentPage;
    }
    if (params.search) {
      requestParams.search = params.search;
    }

    return axios
      .get("/tournaments/all", {
        params: requestParams,
      })
      .then((response) => response.data);
  }
);

export const getTournamentById = createAsyncThunk(
  "tournaments/getTournamentById",
  (id) => {
    return axios
      .get(`/tournaments/${id}`, {})
      .then((response) => response.data);
  }
);
export const getUsersList = createAsyncThunk(
  "tournaments/getUsersList",
  (id) => {
    return axios
      .get(`/tournaments/${id}/available_users`, {})
      .then((response) => response.data);
  }
);
const initialState = {
  loading: true,
  createLoading: false,
  tournaments: [],
  firstTournaments: [],
  totalPages: 0,
  totalTournaments: 0,
  currentPage: 1,
  tournamentById: {
    _id: "643c120e3540c53f34790e26",
    capacity: 10,
    participants: [],
    endDate: "2023-06-19T22:00:00.000Z",
    startDate: "2024-01-09T23:00:00.000Z",
    state: "closedForSubscription",
    mainPicture: "/assets/img/P250.jpg",
    description:
      "Premier tournois de padel organisé par le club de padel Casa Padel",
    title: "Sfax Padel",
  },
  message: "",
  editMessage: "",
  users: [],
};

const tournamentSlice = createSlice({
  name: "tournaments",
  initialState,
  extraReducers: {
    [getTournaments.pending](state) {
      state.loading = true;
    },
    [getTournaments.fulfilled](state, { payload }) {
      state.loading = false;
      state.tournaments = payload.tournaments;
      state.firstTournaments = payload.tournaments.slice(0, 3);
      state.totalPages = payload.totalPages;
      state.totalTournaments = payload.totalTournaments;
      state.currentPage = payload.currentPage;
    },
    [getTournaments.rejected](state) {
      state.loading = true;
    },

    [getTournamentById.pending](state) {
      state.loading = true;
    },
    [getTournamentById.fulfilled](state, { payload }) {
      state.loading = false;
      state.tournamentById = payload;
    },
    [getTournamentById.rejected](state) {
      state.loading = true;
    },
    [getUsersList.pending](state) {
      state.loading = true;
    },
    [getUsersList.fulfilled](state, { payload }) {
      state.users = [
        ...payload.map((user) => ({ ...user, label: user.email })),
      ];
    },
    [getUsersList.rejected](state) {
      state.loading = true;
    },
  },
});

export const selectTournaments = ({ tournaments }) => tournaments.tournaments;
export const selectFirstTournaments = ({ tournaments }) =>
  tournaments.firstTournaments;

export const selectLoading = ({ tournaments }) => tournaments.loading;

export const selectTotalPages = ({ tournaments }) => tournaments.totalPages;
export const selectTotalTournaments = ({ tournaments }) =>
  tournaments.totalTournaments;

export const selectCurrentPage = ({ tournaments }) => tournaments.currentPage;

export const selectTournamentById = ({ tournaments }) =>
  tournaments.tournamentById;
export const selectUsersList = ({ tournaments }) => tournaments.users;

export default tournamentSlice.reducer;
