import React, { useEffect, useState } from "react";
import ScrollToTop from "../../../Shared/ScrollToTop";
import Footer from "../../../layout/Footer/Footer";
import Header from "../../../layout/Header/Header";
import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Images from "../components/image-gal/Images";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import TournoisService from "../../../../api/TournoisService";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";

import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

import "./TournamentEvent.css";
import { Link, useParams } from "react-router-dom";
import {
  getTournamentById,
  getUsersList,
  selectLoading,
  selectTournamentById,
  selectUsersList,
} from "../../../../Store/tournametsSlice";

const TournamentEvent = () => {
  const { eventID } = useParams();
  const dispatch = useDispatch();
  const tournament = useSelector(selectTournamentById);
  const loading = useSelector(selectLoading);
  const usersList = useSelector(selectUsersList);

  const [subscribe_pop_up, setSubscribe_pop_up] = useState(false);

  const [player_1, setPlayer1] = useState({ email: "" });
  const [player_2, setPlayer2] = useState({ email: "" });

  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const subscribe = () => {
    let body = {
      id: tournament._id,
      team: {
        joueurA: player_1.email,
        joueurB: player_2.email,
        confirmed: false,
      },
    };
    TournoisService.subscribeToTournament(body)
      .then((response) => {
        // let data = response.data;
        window.location.reload(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const toggle_popup = () => {
    setSubscribe_pop_up(!subscribe_pop_up);
  };

  const stateMap = {
    openForSubscription: "Ouvert à la souscription",
    closedForSubscription: "Fermé Pour l'abonnement",
    inProgress: "En cours",
    finished: "Terminé",
  };

  useEffect(() => {
    dispatch(getUsersList(eventID));
    dispatch(getTournamentById(eventID));
  }, [dispatch, eventID]);
  return (
    <>
      <Header />
      <div className="tournaments_page_header">
        <h1 className="title title--h1 first-title title__separate">
          <Link className="tournois_header_title" to={"/tournois"}>
            Tournois{" "}
          </Link>
          {">"} {tournament.title}{" "}
        </h1>
      </div>
      {loading ? (
        <div className="spiner_div">
          <CircularProgress size={"10rem"} />
        </div>
      ) : (
        <section
          style={{
            boxSizing: "border-box",
          }}
          className="details_section "
        >
          <Box sx={{ width: "100%", height: "fit-content" }}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              style={{ backgroundColor: "#F9F9F9" }}
            >
              <Grid item xs={6} style={{ minWidth: "max-content" }}>
                <div className="gallery-container">
                  <Images
                    images={tournament.extraPictures}
                    countFrom={5}
                    width={50}
                  />
                </div>
              </Grid>
              <Grid item xs={6} style={{ minWidth: "max-content" }}>
                <div className="tournament-heading">
                  <div className="tournament-title"> {tournament.title}</div>
                  {tournament.state === "openForSubscription" ? (
                    <div
                      className="tournament-state"
                      style={{ background: "#caf5c6" }}
                    >
                      {stateMap[tournament.state]}
                    </div>
                  ) : (
                    <div
                      className="tournament-state"
                      style={{ background: "#f5c6d3" }}
                    >
                      {stateMap[tournament.state]}
                    </div>
                  )}
                </div>
                <div className="tournament-subtitle">Description</div>
                <div className="tournament-p">{tournament.description}</div>
                {tournament.state === "openForSubscription" && (
                  <>
                    {" "}
                    <button
                      className="col-12 more-btn primary-btn"
                      variant="primary"
                      onClick={toggle_popup}
                    >
                      Subscribe
                    </button>
                  </>
                )}

                <div className="tournament-subtitle"> Players</div>
                {tournament.participants && (
                  <div className="tournament-players">
                    <AvatarGroup
                      max={4}
                      style={{ gap: "10px" }}
                      className="team-avatar"
                    >
                      {tournament.participants.length === 0 ? (
                        <Avatar alt="+" src="/static/images/avatar/1.jpg" />
                      ) : (
                        tournament.participants.map(
                          (team, index) => (
                            // team.confirmed ? (
                            <>
                              <Avatar
                                alt={team.joueurA}
                                src="/static/images/avatar/1.jpg"
                              />
                              <Avatar
                                alt={team.joueurB}
                                src="/static/images/avatar/1.jpg"
                              />
                            </>
                          )
                          // ) : (
                          //   <></>
                          // )
                        )
                      )}
                    </AvatarGroup>
                  </div>
                )}
                <div className="tournament-subtitle"> Details</div>
                <div className="tournament-details">
                  <table>
                    <tr>
                      <td>Subscription fees:</td>
                      <td>{tournament.subscriptionFees} DT</td>
                    </tr>
                    <tr>
                      <td>Start date: </td>
                      <td>{tournament.startDate.split("T")[0]}</td>
                    </tr>

                    <tr>
                      <td>Due date: </td>
                      <td>{tournament.endDate.split("T")[0]}</td>
                    </tr>
                    <tr>
                      <td>Prize money:</td>
                      <td>{tournament.prizeMoney} DT</td>
                    </tr>
                  </table>
                </div>
              </Grid>
            </Grid>
          </Box>
        </section>
      )}

      {/* subscribe pop up */}
      <Dialog open={subscribe_pop_up} onClose={toggle_popup}>
        <DialogTitle id="draggable-dialog-title">subscribe</DialogTitle>
        <DialogContent>
          <div>
            <h5>Joueur 1:</h5>

            <Autocomplete
              id="outlined-number"
              // id="combo-box-demo"
              isOptionEqualToValue={(option, value) =>
                option.email === value.email
              }
              getOptionLabel={(option) => option.email}
              options={usersList}
              getOptionDisabled={(option) => option === player_2}
              value={player_1}
              onChange={(event, value) => {
                if (!(value === null)) {
                  setPlayer1(value);
                  if (player_2.email !== "") {
                    setButtonDisabled(false);
                  }
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Joueur 1" />
              )}
            />
            <h5>Joueur 2:</h5>
            <Autocomplete
              id="combo-box-demo"
              isOptionEqualToValue={(option, value) =>
                option.email === value.email
              }
              getOptionLabel={(option) => option.email}
              options={usersList}
              getOptionDisabled={(option) => option === player_1}
              value={player_2}
              onChange={(event, value) => {
                if (!(value === null)) {
                  setPlayer2(value);
                  if (player_1.email !== "") {
                    setButtonDisabled(false);
                  }
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Joueur 2" />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions className="modal-footer">
          <Button color="secondary" onClick={toggle_popup}>
            Cancel
          </Button>
          <Button
            className="primary-btn"
            onClick={subscribe}
            disabled={isButtonDisabled}
          >
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>

      <Footer hideContactInfo displayNewsLetter />

      <ScrollToTop />
    </>
  );
};

export default TournamentEvent;
