import http from "./Httpcommon";
import axios from "axios";

class UserService {
  baseURL = process.env.REACT_APP_BACK_END_BASE_URL;

  register(body) {
    return http.post("/auth/register", body);
  }

  login(body) {
    return http.post("/auth/login", body);
  }

  getuser(accessToken) {
    return http.post("/auth/getuser", accessToken);
  }

  ForgotPassword(email) {
    return http.get(`users/email/forget-password/${email}`);
  }

  ResetPassword(body) {
    return http.post(`users/email/reset-password`, body);
  }

  editProfile(token, body) {
    return axios
      .create({
        baseURL: this.baseURL,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .post("/users/profile/edit", body);
  }
}
export default new UserService();
