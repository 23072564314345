import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";

export const getTerrainNames = createAsyncThunk(
  "reservations/getTerrainNames",
  async (params = {}) => {
    try {
      let { data } = await axios.get("/terrain", {});
      return data;
    } catch (error) {
      console.log("error");

      throw error; // Throw the error to be caught later
    }
  }
);

const getReservationsApi = async (requestParams) => {
  try {
    let { data } = await axios.get("/reservations/all", {
      params: requestParams,
    });
    return data;
  } catch (error) {
    console.log("error");

    throw error; // Throw the error to be caught later
  }
};

export const getReservations = createAsyncThunk(
  "reservations/getReservations",
  async (params = {}) => {
    const requestParams = {};
    if (params.terrain) {
      requestParams.terrain = params.terrain;
    }

    let data = await getReservationsApi(requestParams);
    return data;
  }
);

const cancelReservationApi = async (requestParams) => {
  try {
    const response = await axios.post(
      "/reservations/admin/cancel",
      requestParams,
      {}
    );
    return response.data;
  } catch (error) {
    console.log("error");
    throw error; // Throw the error to be caught later
  }
};

export const cancelReservation = createAsyncThunk(
  "reservations/cancelReservation",
  async (params = {}, { dispatch }) => {
    const requestParams = {};
    if (params.reservationId) {
      requestParams.reservationId = params.reservationId;
    }

    const data = await cancelReservationApi(requestParams);
    return data;
  }
);

const cancelBookingApi = async (requestParams) => {
  try {
    const response = await axios.post(
      "/reservations/admin/cancel/booking",
      requestParams,
      {}
    );
    return response.data;
  } catch (error) {
    console.log("error");
    throw error; // Throw the error to be caught later
  }
};

export const cancelBooking = createAsyncThunk(
  "reservations/CancelBooking",
  async (params = {}, { dispatch }) => {
    const requestParams = {};
    if (params.reservationId) {
      requestParams.reservationId = params.reservationId;
    }
    if (params.reservationId) {
      requestParams.user_id = params.playerId;
    }
    if (params.reservationId) {
      requestParams.cancel_type = params.cancel_type;
    }

    const data = await cancelBookingApi(requestParams);
    return data;
  }
);

const createResrevationApi = async (requestParams) => {
  try {
    const response = await axios.post(
      "/reservations/admin/create",
      requestParams,
      {}
    );
    return response.data;
  } catch (error) {
    console.log("error");
    throw error; // Throw the error to be caught later
  }
};

export const createResrevation = createAsyncThunk(
  "reservations/createResrevation",
  async (params, { dispatch }) => {
    const terrain = params.terrain;

    const data = await createResrevationApi(params);
    await dispatch(getReservations({ terrain }));
    return data;
  }
);

const initialState = {
  loading: true,
  reservations: [
    {
      id: 1,
      title: "event 1",
      date: "2023-10-22T07:00:00",
      players: [
        {
          name: "Ramez Ben Aribia",
          coins: 1,
        },
        {
          name: "Mahdi Hamdi",
          coins: 1,
        },
        {
          name: "Mahdi Bouaziz",
          coins: 1,
        },
        {
          name: "Youssef Abid",
          coins: 1,
        },
      ],
      coins: 4,
      classNames: "busy",
    },
  ],
  terrainNames: {},
};

const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  extraReducers: {
    [getReservations.pending](state) {
      state.loading = true;
    },
    [getReservations.fulfilled](state, { payload }) {
      state.loading = false;
      state.reservations = payload;
    },
    [getReservations.rejected](state) {
      state.loading = true;
    },

    [getTerrainNames.pending](state) {
      state.loading = true;
    },
    [getTerrainNames.fulfilled](state, { payload }) {
      state.loading = false;
      state.terrainNames = payload;
    },
    [getTerrainNames.rejected](state) {
      state.loading = true;
    },
  },
});

export const selectReservations = ({ reservations }) =>
  reservations.reservations;

export const selectTerrainNames = ({ reservations }) =>
  reservations.terrainNames;

export const selectLoading = ({ reservations }) => reservations.loading;

export default reservationsSlice.reducer;
