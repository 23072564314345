import React, { useCallback, useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";

import CircularProgress from "@mui/material/CircularProgress";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import "@fullcalendar/daygrid/main.css";
import ReservationService from "../../../../api/ReservationService";

import { toast } from "react-toastify";

import "./AddReservationPopUp.css";
import { useDispatch } from "react-redux";
import { refreshUserUser } from "../../../../Store/userSlice";

const AddReservationPopUp = ({
  modal,
  toggle,
  terrain,
  terrainNames,
  time,
  setTime,
  selectedDay,
  availableTime,
  getReservationsByDate,
}) => {
  const [payementMethod, setPayementMethod] = useState("jetons");
  const [jetons, setjetons] = useState(1);
  const [numberOfplaces, setNumberOfplaces] = useState(1);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [today, setToday] = useState(new Date());

  const dispatch = useDispatch();

  const alert = useCallback((response) => {
    if (response.status === 201) {
      toast.success("votre réservation est ajoutée avec succès", {
        autoClose: 5000,
        theme: "light",
      });
    } else {
      toast.error(response.data.message, {
        autoClose: 5000,
        theme: "light",
      });
    }
  }, []);

  const addEventClick = async () => {
    setButtonDisabled(true);

    let token = JSON.parse(localStorage.getItem("token")).accessToken;

    let date =
      selectedDay.getFullYear() +
      "-" +
      String(selectedDay.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(selectedDay.getDate()).padStart(2, "0");

    // If time starts with one letter we should add 0
    let backendTime = time;
    if (time === undefined) {
      alert({
        status: 401,
        data: {
          message: "Il n'y a pas de créneau disponible pour ce jour.",
        },
      });
    } else {
      if (time.length === 4) {
        backendTime = "0" + time;
      }

      let body = {
        payementMethod: payementMethod,
        terrain_id: terrain,
        start: date + "T" + backendTime + ":00.000+00:00",
        howMuchPaid: payementMethod === "cash" ? 0 : Number(jetons),
        restByCash: payementMethod === "cash",
        byCash: payementMethod === "cash",
        numberOfPlacesByCash:
          payementMethod === "cash" ? Number(numberOfplaces) : 0,
      };

      if (!availableTime[terrain].includes(time)) {
        alert({
          status: 401,
          data: {
            message: "Veuillez saisir une valeur valide",
          },
        });
      } else {
        await ReservationService.createResrevation(token, body)
          .then((response) => {
            alert(response);
            getReservationsByDate(selectedDay, terrain);
            dispatch(refreshUserUser()).then((result) => {});
            toggle();
          })
          .catch((e) => {
            alert(e.response);
          });
      }
    }
    setButtonDisabled(false);
  };

  useEffect(() => {
    if (modal) {
      let today_var = new Date();
      setToday(today_var);
      getReservationsByDate(selectedDay, terrain);
      setjetons(1);
      setNumberOfplaces(1);
    }
  }, [modal]);

  return (
    <>
      <Dialog open={modal} onClose={toggle}>
        <DialogTitle id="draggable-dialog-title">Réserver</DialogTitle>
        <DialogContent>
          <div>
            <div className="custom-break-line-1"></div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Terrain</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={terrain}
                label="Terrain"
                onChange={(event) =>
                  getReservationsByDate(selectedDay, event.target.value)
                }
              >
                {terrainNames.map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="custom-break-line-3"></div>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  className="mt-8 mb-16 date-picker-class"
                  label="Date de reservation"
                  id="DatePicker"
                  minDate={today}
                  value={selectedDay}
                  onChange={(event) => getReservationsByDate(event, terrain)}
                />
              </LocalizationProvider>
            </FormControl>
            <div className="custom-break-line-2"></div>
            <h5>Créneau horaire: </h5>
            {availableTime[terrain] ? (
              <ToggleButtonGroup
                className="custom-toggle-button-group"
                value={time}
                exclusive
                onChange={(event) => setTime(event.target.value)}
              >
                {availableTime[terrain].map((option) => (
                  <ToggleButton
                    className="custom-toggle-button"
                    key={option}
                    value={option}
                  >
                    {option}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            ) : (
              <></>
            )}
            <Tabs
              value={payementMethod}
              onChange={(e, payementMethod) =>
                setPayementMethod(payementMethod)
              }
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="jetons" label="jetons" />
              <Tab value="cash" label="espèces" />
            </Tabs>
            {payementMethod === "jetons" ? (
              <>
                <div className="custom-break-line-3"></div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Jetons</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={jetons}
                    label="Terrain"
                    onChange={(e) => setjetons(e.target.value)}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                {" "}
                {/* <h5>sélectionner le nombre de places: </h5> */}
                <div className="custom-break-line-3"></div>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Nombre de places
                  </InputLabel>
                  <Select
                    labelId="test-select-label"
                    id="demo-simple-select"
                    value={numberOfplaces}
                    label="Nombre de places"
                    onChange={(e) => setNumberOfplaces(e.target.value)}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className="modal-footer">
          <Button color="secondary" onClick={toggle}>
            Annuler
          </Button>
          <Button
            className="primary-btn"
            onClick={addEventClick}
            disabled={isButtonDisabled}
          >
            {isButtonDisabled ? <CircularProgress size={"1rem"} /> : "Reserver"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddReservationPopUp;
