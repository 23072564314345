import { Row } from "react-bootstrap";
import { Card, Col } from "react-bootstrap";

import { useSelector } from "react-redux";

import "./Packs.css";
import { selectPacks } from "../../../../../Store/packSlice";

const Packs = () => {
  const packs = useSelector(selectPacks);
  return (
    <>
      <div
        className="container-fluid text-center"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Row
          style={{
            display: "flex",
            width: "max-content",
            justifyContent: "center",
            height: "auto",
            gap: "30px",
            alignItems: "center",
          }}
        >
          {packs &&
            packs.map((pack, index) => (
              <Col key={index}>
                <Card className=" pack_offer shadow">
                  <Card.Body>
                    <Card.Title>{pack?.titre}</Card.Title>
                    <div className="jetons">{pack?.nombreJeton} Jetons</div>
                    <div className="text">{pack?.description}</div>
                    <div className="prix">{pack?.prix} DT</div>
                    {/* <Card.Img className="imm" variant="top" src={pic} /> */}
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
};

export default Packs;
